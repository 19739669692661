import {
  getDailyBinaryMetric,
  getDailyContinuousMetric,
  getOverallBinaryMetric,
  getOverallContinuousMetric,
} from '../api/firestore/Metrics';
import {BinaryMetric, DailyBinaryMetric} from '../objects/BinaryMetric';
import {Models} from '../../configuration/metrics/Metrics';
import {ContinuousMetric, DailyContinuousMetric} from '../objects/ContinuousMetric';
import {getVariantFields} from '../api/firestore/Variants';

export const getLatestMetricDate = () => {
  return new Date(Date.now() - 6 * 24 * 3600 * 1000);
};
const formatNumber = (value, options = {}) => {
  value = value < 0 ? 0 : value;
  return value.toLocaleString(undefined, {
    maximumFractionDigits: 0,
    minimumIntegerDigits: 2,
    ...options,
  });
};

const formatPercentage = (value, options = {}) => {
  value = value < 0 ? 0 : value;
  return value.toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
    ...options,
  });
};

const formatCurrency = (value, options = {}) => {
  value = value < 0 ? 0 : value;
  const curr = localStorage.getItem('currency') || '$';
  return value.toLocaleString('en-us', {
    ...options,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: curr === '$' ? 'USD' : 'GBP',
  });
};

const replaceNaN = (value, formatFn) => {
  return isNaN(value) ? '-' : formatFn(value);
};

/**
 * Given a date, return an array with the last 7 days of dates
 * @param days
 * @param date
 * @returns {Date[]} with last 7 days
 */
export const generateArrayOfDates = (days, date) => {
  return [...Array(days)].map((_, i) => {
    let d = new Date(date);
    d.setDate(d.getDate() - days + i + 1);
    return d;
  });
};

const getAllVariantsOverallMetrics = async (experiment, model) => {
  const overallBinaryMetricsPromises = experiment.activeVariants.map(async varnt => {
    try {
      return model === Models.TSBinary
        ? await getOverallBinaryMetric(experiment.id, varnt)
        : await getOverallContinuousMetric(experiment.id, varnt);
    } catch (e) {
      return model === Models.TSBinary
        ? new BinaryMetric(NaN, NaN, experiment.id, varnt)
        : new ContinuousMetric(NaN, NaN, NaN, experiment.id, varnt);
    }
  });

  // Use Promise.all to wait for all the promises to resolve
  return await Promise.all(overallBinaryMetricsPromises);
};

const generateBinaryStatsCards = (experiment, overallMetrics, metricConfig) => {
  const totalEmailsSent = overallMetrics.reduce(
    (acc, curr) => acc + curr.successCount + curr.failureCount,
    0
  );
  const totalSuccess = overallMetrics.reduce((acc, curr) => acc + curr.successCount, 0);
  const abTestSuccesses = overallMetrics.reduce((acc, curr) => {
    const estimatedSuccessPerVariant =
      curr.getSuccessRate() * (totalEmailsSent / experiment.activeVariants.length);
    return acc + estimatedSuccessPerVariant;
  }, 0);

  return calculateStats(
    overallMetrics,
    totalEmailsSent,
    totalSuccess,
    abTestSuccesses,
    metricConfig
  );
};

const generateContinuousStatsCards = (experiment, overallMetrics, metricConfig) => {
  const totalEmailsSent = overallMetrics.reduce((acc, curr) => acc + curr.count, 0);

  const totalActualSuccess = experiment.mabRev;
  const totalSuccessAB = experiment.splitRev;

  return calculateStats(
    overallMetrics,
    totalEmailsSent,
    totalActualSuccess,
    totalSuccessAB,
    metricConfig
  );
};
const calculateStats = (
  overallMetrics,
  totalEmailsSent,
  totalSuccess,
  abTestSuccesses,
  metricConfig
) => {
  const totalIncrease = totalSuccess - abTestSuccesses;
  const percentageIncrease = totalIncrease / totalEmailsSent;

  return [
    {
      name: metricConfig.labels.statsCards[0],
      value: replaceNaN(totalEmailsSent, formatNumber),
    },
    {
      name: metricConfig.labels.statsCards[1],
      value:
        metricConfig.model === Models.TSBinary
          ? replaceNaN(percentageIncrease, formatPercentage)
          : replaceNaN(percentageIncrease, formatCurrency),
    },
    {
      name: metricConfig.labels.statsCards[2],
      value:
        metricConfig.model === Models.TSBinary
          ? replaceNaN(totalIncrease, formatNumber)
          : replaceNaN(totalIncrease, formatCurrency),
    },
    {
      name: metricConfig.labels.statsCards[3],
      value:
        metricConfig.model === Models.TSBinary
          ? replaceNaN(totalSuccess, formatNumber)
          : replaceNaN(totalSuccess, formatCurrency),
    },
  ];
};

const getDaysOfMetricsFromDate = async (experiment, days, date, model) => {
  const dateArray = generateArrayOfDates(days, date);

  // Iterate over each variant and resolve the promises for each date
  return await Promise.all(
    experiment.activeVariants.map(async variant => {
      return Promise.all(
        dateArray.map(async metricDate => {
          try {
            // Retrieve metric for variant and given date
            return model === Models.TSBinary
              ? await getDailyBinaryMetric(experiment.id, variant, metricDate)
              : await getDailyContinuousMetric(experiment.id, variant, metricDate);
          } catch (e) {
            console.error(e);
            console.error(
              'Unable to get metric: Experiment: ' +
                experiment.id +
                ' Variant:' +
                variant +
                ' Date: ' +
                metricDate
            );
            return model === Models.TSBinary
              ? new DailyBinaryMetric(NaN, NaN, experiment.id, variant, metricDate)
              : new DailyContinuousMetric(NaN, NaN, NaN, experiment.id, variant, metricDate);
          }
        })
      );
    })
  );
};

const generateTableStats = (
  experiment,
  overallMetrics,
  dailyMetrics,
  latestMetricDate,
  model,
  variantsDetails
) => {
  const isBinary = model === Models.TSBinary;
  const latestMetrics = dailyMetrics
    .flat()
    .filter(met => met.date.valueOf() === latestMetricDate.valueOf());

  const totalLatestSent = latestMetrics.reduce((acc, curr) => {
    return acc + (isBinary ? curr.successCount + curr.failureCount : curr.count);
  }, 0);

  const tableStats = experiment.activeVariants.map(varnt => {
    const variantDetails = variantsDetails.find(varDet => varDet.variantId === varnt);
    const overallMetric = overallMetrics.find(met => {
      return met.variantId === varnt;
    });
    const latestDailyMetric = latestMetrics.find(met => {
      return met.variantId === varnt;
    });
    const allocation =
      (isBinary ? latestDailyMetric.getTotalSent() : latestDailyMetric.count) / totalLatestSent;
    return isBinary
      ? {
          variantName: variantDetails.variantName,
          metric: replaceNaN(overallMetric.getSuccessRate(), formatPercentage),
          allocation: replaceNaN(allocation, formatPercentage),
          emailsSent: replaceNaN(overallMetric.getTotalSent(), formatNumber),
          templateId: varnt,
        }
      : {
          variantName: variantDetails.variantName,
          metric: replaceNaN(overallMetric.mean, formatCurrency),
          allocation: replaceNaN(allocation, formatPercentage),
          emailsSent: replaceNaN(overallMetric.count, formatNumber),
          templateId: varnt,
        };
  });

  const sortedTableStats = tableStats
    .sort((a, b) => parseFloat(b.metric) - parseFloat(a.metric))
    .map((curr, index) => {
      return {...curr, id: index, rank: index + 1};
    });

  return sortedTableStats;
};

const generateSuccessChartData = (experiment, dailyMetrics, model, variantsDetails) => {
  return experiment.activeVariants.map(varnt => {
    const variantDailyMetrics = dailyMetrics.flat().filter(met => met.variantId === varnt);
    const sortedVariantDailyMetrics = variantDailyMetrics.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    const variantDetails = variantsDetails.find(varDet => varDet.variantId === varnt);

    return {
      label: variantDetails.variantName,
      data: sortedVariantDailyMetrics.map(met =>
        model === Models.TSBinary ? met.getSuccessRate() * 100 : met.mean
      ),
    };
  });
};

const generateAllocationChartData = (
  experiment,
  dailyMetrics,
  latestMetricDate,
  model,
  variantsDetails
) => {
  const dateArray = generateArrayOfDates(30, latestMetricDate);
  return experiment.activeVariants.map(varnt => {
    const variantAllocations = dateArray.map(date => {
      const givenDateMetrics = dailyMetrics
        .flat()
        .filter(met => met.date.valueOf() === date.valueOf());
      const givenDateVarntMetric = givenDateMetrics.find(met => met.variantId === varnt);
      const totalOnGivenDate = givenDateMetrics.reduce(
        (acc, curr) => acc + (model === Models.TSBinary ? curr.getTotalSent() : curr.count),
        0
      );
      return (
        (model === Models.TSBinary
          ? givenDateVarntMetric.getTotalSent() / totalOnGivenDate
          : givenDateVarntMetric.count / totalOnGivenDate) * 100
      );
    });
    const variantDetails = variantsDetails.find(varDet => varDet.variantId === varnt);

    return {label: variantDetails.variantName, data: variantAllocations};
  });
};

export const processBinaryMetrics = async (experiment, metricConfig) => {
  // Date when latest metric will be available
  return processMetrics(experiment, metricConfig, generateBinaryStatsCards);
};

export const processContinuousMetrics = async (experiment, metricConfig) => {
  return processMetrics(experiment, metricConfig, generateContinuousStatsCards);
};

export const processMetrics = async (experiment, metricConfig, generateStatsCardsFn) => {
  // Date when latest metric will be available
  const latestMetricDate = getLatestMetricDate();
  let successChartData;
  let allocationChartData;
  let statsCards;
  let tableStats;

  const overallMetrics = await getAllVariantsOverallMetrics(experiment, metricConfig.model);
  const dailyMetrics = await getDaysOfMetricsFromDate(
    experiment,
    30,
    latestMetricDate,
    metricConfig.model
  );

  const variantsDetails = await Promise.all(
    experiment.activeVariants.map(variantId => {
      return getVariantFields(experiment.id, variantId);
    })
  );

  successChartData = generateSuccessChartData(
    experiment,
    dailyMetrics,
    metricConfig.model,
    variantsDetails
  );
  allocationChartData = generateAllocationChartData(
    experiment,
    dailyMetrics,
    latestMetricDate,
    metricConfig.model,
    variantsDetails
  );

  try {
    const hasNaNInArray = overallMetrics.some(obj =>
      Object.values(obj).some(value => Number.isNaN(value))
    );

    statsCards = generateStatsCardsFn(experiment, overallMetrics, metricConfig);
    if (hasNaNInArray) {
      throw new Error(`NaN found in overall continuous metrics`);
    }
  } catch (e) {
    statsCards = [
      {
        name: metricConfig.labels.statsCards[0],
        value: '-',
      },
      {
        name: metricConfig.labels.statsCards[1],
        value: '-',
      },
      {
        name: metricConfig.labels.statsCards[2],
        value: '-',
      },
      {
        name: metricConfig.labels.statsCards[3],
        value: '-',
      },
    ];
    console.error(e);
    console.error('Unable to get overall metric: Experiment: ' + experiment.id);
  }

  tableStats = generateTableStats(
    experiment,
    overallMetrics,
    dailyMetrics,
    latestMetricDate,
    metricConfig.model,
    variantsDetails
  );

  return [successChartData, allocationChartData, statsCards, tableStats];
};
